<template>
  <div>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <div
            class="
              brand-visitors-image
              item-img-container
              bg-white
              h-auto
              flex
              items-center
              justify-center
              mb-4
              cursor-pointer
            "
          >
            <img :src="brand.logo" class="grid-view-img" />
          </div>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full text-center mt-4">
          <h3 class="text-primary">العلامة التجارية</h3>
          <h3 class="sub-data font-bold mt-6">{{ brand.name }}</h3>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full text-center mt-4">
          <h3 class="text-primary">عدد الزيارات بالكامل</h3>
          <h3 class="sub-data font-bold mt-6">{{ brand.visitors.all_time }}</h3>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full text-center mt-4">
          <h3 class="text-primary">عدد زيارات هذا الشهر</h3>
          <h3 class="sub-data font-bold mt-6">
            {{ brand.visitors.current_month }}
          </h3>
        </div>
      </div>
      <div class="vx-row mt-6">
        <div
          style="color: #282c34"
          class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4"
        >
          <v-select
            color="primary"
            name="selected-value"
            :options="years"
            @input="getBrandVisitors"
            v-model="year"
            class="w-full"
          ></v-select>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-3/4 mt-4">
          <vue-apex-charts
            type="bar"
            id="apex"
            height="400"
            width="100%"
            :options="brandVisitors"
            :series="brandVisitors.series"
          ></vue-apex-charts>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ApexCharts from "apexcharts";
import vSelect from "vue-select";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      brand: [],
      id: this.$route.query.id,
      year: new Date().getFullYear(),
      years: [],
      month_data: [],
      brandVisitors: {
        series: [
          {
            name: "زيارة",
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 12, 34],
          },
        ],
        chart: {
          type: "bar",
          height: 400,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        fill: {
          colors: ["#af9182"],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
    };
  },
  components: {
    vSelect,
    VueApexCharts,
  },
  methods: {
    ...mapActions("brandVisitors", ["fetchYearVisitors"]),
    getBrandVisitors() {
      this.$vs.loading();

      this.fetchYearVisitors({
        brand_id: this.id,
        year: this.year,
      }).then((response) => {
        this.brand = response.brand;
        this.brand.visitors = {
          all_time: response.all_time,
          months: response.months,
          current_month: response.current_month,
        };
        this.years = response.year_range;
        this.brandVisitors.series[0].data = response.months;
        this.$vs.loading.close();
        var apex = document.querySelector('#apex');
        var chart = new ApexCharts(apex, this.brandVisitors);
        chart.render();
      });
    },
  },
  mounted() {
    this.getBrandVisitors();
  },
};
</script>

<style lang="scss">
.brand-visitors-image img {
  width: 200px;
  height: 200px;
  border-radius: 30px;
  border: 3px solid #bea599;
}
.sub-data {
  color: #6f6f6f;
}
</style>
